// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-core-share-share-block-template-js": () => import("/opt/build/repo/surveys/2018/website/src/core/share/ShareBlockTemplate.js" /* webpackChunkName: "component---src-core-share-share-block-template-js" */),
  "component---src-modules-sections-section-introduction-template-js": () => import("/opt/build/repo/surveys/2018/website/src/modules/sections/SectionIntroductionTemplate.js" /* webpackChunkName: "component---src-modules-sections-section-introduction-template-js" */),
  "component---src-modules-tools-tool-template-js": () => import("/opt/build/repo/surveys/2018/website/src/modules/tools/ToolTemplate.js" /* webpackChunkName: "component---src-modules-tools-tool-template-js" */),
  "component---src-modules-sections-section-other-tools-template-js": () => import("/opt/build/repo/surveys/2018/website/src/modules/sections/SectionOtherToolsTemplate.js" /* webpackChunkName: "component---src-modules-sections-section-other-tools-template-js" */),
  "component---src-modules-sections-section-conclusion-template-js": () => import("/opt/build/repo/surveys/2018/website/src/modules/sections/SectionConclusionTemplate.js" /* webpackChunkName: "component---src-modules-sections-section-conclusion-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/surveys/2018/website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-awards-js": () => import("/opt/build/repo/surveys/2018/website/src/pages/Awards.js" /* webpackChunkName: "component---src-pages-awards-js" */),
  "component---src-pages-conclusion-js": () => import("/opt/build/repo/surveys/2018/website/src/pages/Conclusion.js" /* webpackChunkName: "component---src-pages-conclusion-js" */),
  "component---src-pages-connections-js": () => import("/opt/build/repo/surveys/2018/website/src/pages/Connections.js" /* webpackChunkName: "component---src-pages-connections-js" */),
  "component---src-pages-demographics-js": () => import("/opt/build/repo/surveys/2018/website/src/pages/Demographics.js" /* webpackChunkName: "component---src-pages-demographics-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/surveys/2018/website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-introduction-js": () => import("/opt/build/repo/surveys/2018/website/src/pages/Introduction.js" /* webpackChunkName: "component---src-pages-introduction-js" */),
  "component---src-pages-mosaic-js": () => import("/opt/build/repo/surveys/2018/website/src/pages/Mosaic.js" /* webpackChunkName: "component---src-pages-mosaic-js" */),
  "component---src-pages-opinions-js": () => import("/opt/build/repo/surveys/2018/website/src/pages/Opinions.js" /* webpackChunkName: "component---src-pages-opinions-js" */),
  "component---src-pages-other-tools-js": () => import("/opt/build/repo/surveys/2018/website/src/pages/Other-tools.js" /* webpackChunkName: "component---src-pages-other-tools-js" */),
  "component---src-pages-support-js": () => import("/opt/build/repo/surveys/2018/website/src/pages/Support.js" /* webpackChunkName: "component---src-pages-support-js" */)
}

exports.data = () => import("/opt/build/repo/surveys/2018/website/.cache/data.json")

