import React from 'react'

// eslint-disable-next-line
const Logo = ({ width = '100%' }) => (
    <svg width={width} viewBox="0 0 492 296" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="99" y="99" width="98" height="98" stroke="#4D4F4F" strokeWidth="2" />
        <path
            d="M140.526 161L147.402 135.872H142.47L139.302 148.58L137.43 156.5H137.178L135.342 148.58L132.21 135.872H127.026L133.902 161H140.526ZM168.34 161V157.544H166.144V148.796C166.144 144.332 163.192 141.992 158.332 141.992C154.3 141.992 151.708 143.612 150.556 145.664L153.184 147.896C154.192 146.456 155.524 145.448 157.864 145.448C160.42 145.448 161.536 146.672 161.536 149.012V150.236H158.26C152.788 150.236 150.016 152.108 150.016 155.852C150.016 159.344 152.428 161.432 156.136 161.432C159.016 161.432 161.068 160.1 161.752 157.796H161.932C162.148 159.776 163.408 161 165.64 161H168.34ZM157.684 158.264C155.776 158.264 154.588 157.472 154.588 155.996V155.276C154.588 153.728 155.848 152.9 158.368 152.9H161.536V155.384C161.536 157.148 159.88 158.264 157.684 158.264Z"
            fill="#72D7D1"
        />
        <path
            opacity="0.5"
            d="M117.864 118.896C117.864 116.304 116.01 114.594 113.832 114.594C111.996 114.594 111.114 115.494 110.664 116.25H110.448V114.45C110.448 112.668 111.582 111.498 113.49 111.498C115.362 111.498 116.244 112.506 116.244 114.036H117.72C117.72 111.768 116.136 110.148 113.49 110.148C110.79 110.148 108.972 111.93 108.972 114.522V118.914C108.972 121.524 110.898 123.252 113.4 123.252C115.938 123.252 117.864 121.488 117.864 118.896ZM113.4 115.872C115.182 115.872 116.388 117.096 116.388 118.896C116.388 120.696 115.182 121.902 113.4 121.902C111.654 121.902 110.448 120.696 110.448 118.896C110.448 117.096 111.654 115.872 113.4 115.872Z"
            fill="#fff"
        />
        <rect x="197" y="99" width="98" height="98" stroke="#4D4F4F" strokeWidth="2" />
        <path
            d="M234.926 161.432C241.01 161.432 244.286 158.156 244.286 153.476C244.286 149.732 242.162 147.248 237.842 146.456L235.214 145.988C232.442 145.52 231.398 144.584 231.398 142.676C231.398 140.444 232.802 139.292 235.502 139.292C238.022 139.292 239.894 140.3 241.226 141.884L243.998 138.932C242.27 136.772 239.39 135.44 235.466 135.44C229.994 135.44 226.646 138.104 226.646 142.892C226.646 146.384 228.446 149.192 233.09 149.984L235.754 150.416C238.49 150.848 239.534 151.82 239.534 153.8C239.534 156.212 237.95 157.58 235.07 157.58C232.586 157.58 230.39 156.572 228.518 154.412L225.674 157.364C227.582 159.812 230.642 161.432 234.926 161.432ZM257.52 161.432C261.516 161.432 264 159.596 265.26 156.968L261.804 154.916C260.976 156.572 259.752 157.688 257.556 157.688C254.748 157.688 253.236 155.924 253.236 153.26V150.164C253.236 147.464 254.784 145.736 257.484 145.736C259.572 145.736 260.76 146.816 261.408 148.4L264.972 146.456C263.748 143.792 261.408 141.992 257.484 141.992C251.868 141.992 248.412 145.736 248.412 151.712C248.412 157.688 251.832 161.432 257.52 161.432Z"
            fill="#72D7D1"
        />
        <path
            opacity="0.5"
            d="M206.342 110.4V111.768H213.722L208.538 123H210.158L215.378 111.696V110.4H206.342Z"
            fill="#fff"
        />
        <rect x="1" y="99" width="98" height="98" stroke="#4D4F4F" strokeWidth="2" />
        <path
            d="M33.2381 135.872V139.76H41.7341V153.584C41.7341 156.284 40.5101 157.544 38.3861 157.544C36.0461 157.544 35.0381 155.996 34.6061 153.908L30.1781 154.844C30.9341 158.84 33.6341 161.432 38.3861 161.432C43.2821 161.432 46.4861 158.588 46.4861 154.124V135.872H33.2381ZM70.34 161V157.544H68.144V148.796C68.144 144.332 65.192 141.992 60.332 141.992C56.3 141.992 53.708 143.612 52.556 145.664L55.184 147.896C56.192 146.456 57.524 145.448 59.864 145.448C62.42 145.448 63.536 146.672 63.536 149.012V150.236H60.26C54.788 150.236 52.016 152.108 52.016 155.852C52.016 159.344 54.428 161.432 58.136 161.432C61.016 161.432 63.068 160.1 63.752 157.796H63.932C64.148 159.776 65.408 161 67.64 161H70.34ZM59.684 158.264C57.776 158.264 56.588 157.472 56.588 155.996V155.276C56.588 153.728 57.848 152.9 60.368 152.9H63.536V155.384C63.536 157.148 61.88 158.264 59.684 158.264Z"
            fill="#72D7D1"
        />
        <path
            opacity="0.5"
            d="M11.278 110.4V117.132H13.528C13.816 116.592 14.41 115.836 15.742 115.836C17.326 115.836 18.388 116.916 18.388 118.734C18.388 120.678 17.29 121.902 15.382 121.902C13.456 121.902 12.268 120.768 12.268 118.824V118.554H10.81V118.878C10.81 121.542 12.664 123.252 15.382 123.252C18.1 123.252 19.882 121.326 19.882 118.734C19.882 116.178 18.208 114.522 16.03 114.522C14.248 114.522 13.42 115.332 12.952 116.196H12.736V111.75H19.54V110.4H11.278Z"
            fill="#fff"
        />
        <rect x="393" y="99" width="98" height="98" stroke="#4D4F4F" strokeWidth="2" />
        <path
            d="M428.01 161V151.424H432.834C437.694 151.424 440.394 148.4 440.394 143.648C440.394 138.86 437.694 135.872 432.834 135.872H423.294V161H428.01ZM428.01 139.724H431.862C434.382 139.724 435.426 140.732 435.426 142.964V144.332C435.426 146.564 434.382 147.572 431.862 147.572H428.01V139.724ZM461.044 161V157.364H453.484V146.06H461.044V142.424H453.484V135.872H449.2V140.372C449.2 141.92 448.624 142.424 447.148 142.424H443.476V146.06H448.876V155.96C448.876 158.912 450.532 161 454.06 161H461.044Z"
            fill="#72D7D1"
        />
        <path
            opacity="0.5"
            d="M402.72 114.504C402.72 117.096 404.574 118.806 406.752 118.806C408.588 118.806 409.488 117.906 409.92 117.15H410.136V118.95C410.136 120.732 409.002 121.902 407.112 121.902C405.24 121.902 404.358 120.894 404.358 119.364H402.882C402.882 121.632 404.448 123.252 407.112 123.252C409.794 123.252 411.612 121.47 411.612 118.878V114.486C411.612 111.876 409.686 110.148 407.184 110.148C404.646 110.148 402.72 111.912 402.72 114.504ZM407.184 117.528C405.402 117.528 404.196 116.304 404.196 114.504C404.196 112.704 405.402 111.498 407.184 111.498C408.93 111.498 410.136 112.704 410.136 114.504C410.136 116.304 408.93 117.528 407.184 117.528Z"
            fill="#fff"
        />
        <rect x="295" y="99" width="98" height="98" stroke="#4D4F4F" strokeWidth="2" />
        <path
            d="M330.01 151.244H332.854L337.642 161H342.826L337.462 150.704C340.594 150.02 342.394 147.32 342.394 143.648C342.394 138.86 339.694 135.872 334.834 135.872H325.294V161H330.01V151.244ZM330.01 147.572V139.724H333.862C336.382 139.724 337.426 140.732 337.426 142.964V144.332C337.426 146.564 336.382 147.572 333.862 147.572H330.01ZM355.952 139.544C358.112 139.544 358.904 138.5 358.904 137.204V136.34C358.904 135.044 358.112 134 355.952 134C353.792 134 353 135.044 353 136.34V137.204C353 138.5 353.792 139.544 355.952 139.544ZM347.528 161H363.944V157.364H358.256V142.424H347.528V146.06H353.648V157.364H347.528V161Z"
            fill="#72D7D1"
        />
        <path
            opacity="0.5"
            d="M305.008 113.514C305.008 115.26 306.286 116.052 307.186 116.34V116.556C306.25 116.754 304.774 117.69 304.774 119.634C304.774 121.776 306.628 123.252 309.328 123.252C312.01 123.252 313.882 121.776 313.882 119.634C313.882 117.69 312.406 116.754 311.452 116.556V116.34C312.352 116.052 313.648 115.26 313.648 113.514C313.648 111.534 311.956 110.148 309.328 110.148C306.682 110.148 305.008 111.534 305.008 113.514ZM306.484 113.64C306.484 112.308 307.636 111.498 309.328 111.498C311.02 111.498 312.172 112.308 312.172 113.64C312.172 114.972 311.02 115.782 309.328 115.782C307.636 115.782 306.484 114.972 306.484 113.64ZM306.25 119.526C306.25 117.996 307.492 117.132 309.328 117.132C311.146 117.132 312.406 117.996 312.406 119.526C312.406 121.056 311.146 121.902 309.328 121.902C307.492 121.902 306.25 121.056 306.25 119.526Z"
            fill="#fff"
        />
        <rect x="197" y="197" width="98" height="98" stroke="#4D4F4F" strokeWidth="2" />
        <path
            d="M244.214 255.04H231.65L237.122 250.612C240.758 247.696 243.53 244.996 243.53 240.892C243.53 236.572 240.47 233.44 235.106 233.44C229.886 233.44 227.15 236.392 226.07 239.704L230.174 241.252C230.786 239.164 232.082 237.508 234.71 237.508C237.086 237.508 238.526 238.804 238.526 241.18V241.54C238.526 243.664 237.23 245.428 234.566 247.732L226.502 254.608V259H244.214V255.04ZM256.8 259.432C263.352 259.432 266.304 254.644 266.304 246.436C266.304 238.228 263.352 233.44 256.8 233.44C250.248 233.44 247.296 238.228 247.296 246.436C247.296 254.644 250.248 259.432 256.8 259.432ZM256.8 255.652C253.344 255.652 252.084 252.988 252.084 248.668V244.204C252.084 239.884 253.344 237.22 256.8 237.22C260.256 237.22 261.516 239.884 261.516 244.204V248.668C261.516 252.988 260.256 255.652 256.8 255.652ZM256.8 248.56C258.456 248.56 259.032 247.804 259.032 246.832V246.04C259.032 245.068 258.456 244.312 256.8 244.312C255.144 244.312 254.568 245.068 254.568 246.04V246.832C254.568 247.804 255.144 248.56 256.8 248.56Z"
            fill="#FE6A6A"
        />
        <path
            opacity="0.5"
            d="M209.708 208.4L206.108 213.836H207.782L210.338 209.84H210.554V221H212.03L212.048 208.4H209.708ZM214.39 213.296V216.104C214.39 219.398 216.118 221.252 219.016 221.252C221.896 221.252 223.624 219.398 223.624 216.104V213.296C223.624 210.002 221.86 208.148 219.016 208.148C216.118 208.148 214.39 210.002 214.39 213.296ZM215.866 213.242C215.866 210.866 216.91 209.498 219.016 209.498C221.104 209.498 222.148 210.866 222.148 213.242V216.158C222.148 218.534 221.104 219.902 219.016 219.902C216.91 219.902 215.866 218.534 215.866 216.158V213.242Z"
            fill="#fff"
        />
        <rect x="295" y="197" width="98" height="98" stroke="#4D4F4F" strokeWidth="2" />
        <path
            d="M343.006 259V255.148H337.318V233.872H330.082L323.854 241.288L326.806 243.772L332.278 237.292H332.602V255.148H325.69V259H343.006ZM354.8 259.432C360.776 259.432 364.088 256.3 364.088 252.124C364.088 248.812 362.036 246.796 359.048 246.004V245.716C361.604 244.816 363.332 242.872 363.332 239.956C363.332 236.104 360.236 233.44 354.8 233.44C349.364 233.44 346.268 236.104 346.268 239.956C346.268 242.872 347.996 244.816 350.552 245.716V246.004C347.564 246.796 345.512 248.812 345.512 252.124C345.512 256.3 348.824 259.432 354.8 259.432ZM354.8 255.76C351.992 255.76 350.408 254.392 350.408 252.16V251.44C350.408 249.172 351.956 247.804 354.8 247.804C357.644 247.804 359.192 249.172 359.192 251.44V252.16C359.192 254.392 357.608 255.76 354.8 255.76ZM354.8 244.312C352.172 244.312 350.732 243.016 350.732 240.928V240.424C350.732 238.372 352.172 237.112 354.8 237.112C357.428 237.112 358.868 238.372 358.868 240.424V240.928C358.868 243.016 357.428 244.312 354.8 244.312Z"
            fill="#FE6A6A"
        />
        <path
            opacity="0.5"
            d="M307.708 208.4L304.108 213.836H305.782L308.338 209.84H308.554V221H310.03L310.048 208.4H307.708ZM315.126 208.4L311.526 213.836H313.2L315.756 209.84H315.972V221H317.448L317.466 208.4H315.126Z"
            fill="#fff"
        />
        <rect x="99" y="1" width="98" height="98" stroke="#4D4F4F" strokeWidth="2" />
        <path
            d="M147.582 63L140.49 37.872H133.938L126.882 63H131.634L133.362 56.628H140.814L142.578 63H147.582ZM140.022 52.776H134.19L136.962 42.516H137.25L140.022 52.776ZM167.044 63V59.364H159.484V48.06H167.044V44.424H159.484V37.872H155.2V42.372C155.2 43.92 154.624 44.424 153.148 44.424H149.476V48.06H154.876V57.96C154.876 60.912 156.532 63 160.06 63H167.044Z"
            fill="#D0D0D0"
        />
        <path
            opacity="0.5"
            d="M108.882 16.432V16.9H110.358V16.522C110.358 14.578 111.528 13.498 113.436 13.498C115.056 13.498 116.244 14.47 116.244 16.144C116.244 17.962 114.948 18.754 112.806 19.384C110.412 20.086 108.864 21.04 108.864 23.542V25H117.648V23.65H110.358V23.362C110.358 21.634 111.438 21.184 113.454 20.572C115.92 19.816 117.72 18.736 117.72 16.198C117.72 13.768 115.938 12.148 113.436 12.148C110.772 12.148 108.882 13.786 108.882 16.432Z"
            fill="#fff"
        />
        <rect x="197" y="1" width="98" height="98" stroke="#4D4F4F" strokeWidth="2" />
        <path
            d="M254.071 63V59.112H242.911V52.2H253.675V48.312H242.911V41.76H254.071V37.872H238.195V63H254.071Z"
            fill="#D0D0D0"
        />
        <path
            opacity="0.5"
            d="M207.026 12.4V13.768H213.56L209.636 17.224V18.61H211.436C212.966 18.61 214.226 19.51 214.226 21.166C214.226 22.732 213.074 23.902 211.292 23.902C209.366 23.902 208.142 22.84 208.142 20.95V20.23H206.666V21.004C206.666 23.614 208.574 25.252 211.31 25.252C213.758 25.252 215.702 23.596 215.702 21.202C215.702 18.88 214.064 17.44 211.976 17.44H211.526V17.242L215.576 13.714V12.4H207.026Z"
            fill="#fff"
        />
        <rect x="393" y="1" width="98" height="98" stroke="#4D4F4F" strokeWidth="2" />
        <path
            d="M431.214 63.432C437.766 63.432 440.718 58.644 440.718 50.436C440.718 42.228 437.766 37.44 431.214 37.44C424.662 37.44 421.71 42.228 421.71 50.436C421.71 58.644 424.662 63.432 431.214 63.432ZM431.214 59.58C427.866 59.58 426.714 56.916 426.714 52.596V48.276C426.714 43.956 427.866 41.292 431.214 41.292C434.562 41.292 435.714 43.956 435.714 48.276V52.56C435.714 56.916 434.562 59.58 431.214 59.58ZM444.7 63H461.044V59.364H454.996V48.06H461.872V44.424H454.996V39.996H461.872V36.36H455.608C452.044 36.36 450.388 38.448 450.388 41.4V44.424H444.34V48.06H450.388V59.364H444.7V63Z"
            fill="#D0D0D0"
        />
        <path
            opacity="0.5"
            d="M407.868 12.4L402.396 21.202V22.498H408.912V25H410.388V22.498H412.548V21.148H410.388V12.4H407.868ZM408.696 13.624H408.912V21.148H404.034L408.696 13.624Z"
            fill="#fff"
        />
        <rect x="1" y="1" width="98" height="98" stroke="#4D4F4F" strokeWidth="2" />
        <path
            d="M38.9261 63.432C45.0101 63.432 48.2861 60.156 48.2861 55.476C48.2861 51.732 46.1621 49.248 41.8421 48.456L39.2141 47.988C36.4421 47.52 35.3981 46.584 35.3981 44.676C35.3981 42.444 36.8021 41.292 39.5021 41.292C42.0221 41.292 43.8941 42.3 45.2261 43.884L47.9981 40.932C46.2701 38.772 43.3901 37.44 39.4661 37.44C33.9941 37.44 30.6461 40.104 30.6461 44.892C30.6461 48.384 32.4461 51.192 37.0901 51.984L39.7541 52.416C42.4901 52.848 43.5341 53.82 43.5341 55.8C43.5341 58.212 41.9501 59.58 39.0701 59.58C36.5861 59.58 34.3901 58.572 32.5181 56.412L29.6741 59.364C31.5821 61.812 34.6421 63.432 38.9261 63.432ZM69.044 63V59.364H61.484V48.06H69.044V44.424H61.484V37.872H57.2V42.372C57.2 43.92 56.624 44.424 55.148 44.424H51.476V48.06H56.876V57.96C56.876 60.912 58.532 63 62.06 63H69.044Z"
            fill="#D0D0D0"
        />
        <path
            opacity="0.5"
            d="M13.708 12.4L10.108 17.836H11.782L14.338 13.84H14.554V25H16.03L16.048 12.4H13.708Z"
            fill="#fff"
        />
    </svg>
)

const Logo2 = ({ width }) => (
    <svg width={width} viewBox="0 0 390 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <rect x="293" y="1" width="98" height="98" stroke="#4D4F4F" strokeWidth="2" />
            <path
                d="M341.006 63V59.148H335.318V37.872H328.082L321.854 45.288L324.806 47.772L330.278 41.292H330.602V59.148H323.69V63H341.006ZM352.8 63.432C358.776 63.432 362.088 60.3 362.088 56.124C362.088 52.812 360.036 50.796 357.048 50.004V49.716C359.604 48.816 361.332 46.872 361.332 43.956C361.332 40.104 358.236 37.44 352.8 37.44C347.364 37.44 344.268 40.104 344.268 43.956C344.268 46.872 345.996 48.816 348.552 49.716V50.004C345.564 50.796 343.512 52.812 343.512 56.124C343.512 60.3 346.824 63.432 352.8 63.432ZM352.8 59.76C349.992 59.76 348.408 58.392 348.408 56.16V55.44C348.408 53.172 349.956 51.804 352.8 51.804C355.644 51.804 357.192 53.172 357.192 55.44V56.16C357.192 58.392 355.608 59.76 352.8 59.76ZM352.8 48.312C350.172 48.312 348.732 47.016 348.732 44.928V44.424C348.732 42.372 350.172 41.112 352.8 41.112C355.428 41.112 356.868 42.372 356.868 44.424V44.928C356.868 47.016 355.428 48.312 352.8 48.312Z"
                fill="#FE6A6A"
            />
            <path
                opacity="0.5"
                d="M307.868 12.4L302.396 21.202V22.498H308.912V25H310.388V22.498H312.548V21.148H310.388V12.4H307.868ZM308.696 13.624H308.912V21.148H304.034L308.696 13.624Z"
                fill="#fff"
            />
            <rect x="-1" y="1" width="98" height="98" stroke="#4D4F4F" strokeWidth="2" />
            <path
                d="M36.9261 63.432C43.0101 63.432 46.2861 60.156 46.2861 55.476C46.2861 51.732 44.1621 49.248 39.8421 48.456L37.2141 47.988C34.4421 47.52 33.3981 46.584 33.3981 44.676C33.3981 42.444 34.8021 41.292 37.5021 41.292C40.0221 41.292 41.8941 42.3 43.2261 43.884L45.9981 40.932C44.2701 38.772 41.3901 37.44 37.4661 37.44C31.9941 37.44 28.6461 40.104 28.6461 44.892C28.6461 48.384 30.4461 51.192 35.0901 51.984L37.7541 52.416C40.4901 52.848 41.5341 53.82 41.5341 55.8C41.5341 58.212 39.9501 59.58 37.0701 59.58C34.5861 59.58 32.3901 58.572 30.5181 56.412L27.6741 59.364C29.5821 61.812 32.6421 63.432 36.9261 63.432ZM67.044 63V59.364H59.484V48.06H67.044V44.424H59.484V37.872H55.2V42.372C55.2 43.92 54.624 44.424 53.148 44.424H49.476V48.06H54.876V57.96C54.876 60.912 56.532 63 60.06 63H67.044Z"
                fill="#D0D0D0"
            />
            <path
                opacity="0.5"
                d="M11.708 12.4L8.108 17.836H9.782L12.338 13.84H12.554V25H14.03L14.048 12.4H11.708Z"
                fill="#fff"
            />
            <rect x="195" y="1" width="98" height="98" stroke="#4D4F4F" strokeWidth="2" />
            <path
                d="M242.214 59.04H229.65L235.122 54.612C238.758 51.696 241.53 48.996 241.53 44.892C241.53 40.572 238.47 37.44 233.106 37.44C227.886 37.44 225.15 40.392 224.07 43.704L228.174 45.252C228.786 43.164 230.082 41.508 232.71 41.508C235.086 41.508 236.526 42.804 236.526 45.18V45.54C236.526 47.664 235.23 49.428 232.566 51.732L224.502 58.608V63H242.214V59.04ZM254.8 63.432C261.352 63.432 264.304 58.644 264.304 50.436C264.304 42.228 261.352 37.44 254.8 37.44C248.248 37.44 245.296 42.228 245.296 50.436C245.296 58.644 248.248 63.432 254.8 63.432ZM254.8 59.652C251.344 59.652 250.084 56.988 250.084 52.668V48.204C250.084 43.884 251.344 41.22 254.8 41.22C258.256 41.22 259.516 43.884 259.516 48.204V52.668C259.516 56.988 258.256 59.652 254.8 59.652ZM254.8 52.56C256.456 52.56 257.032 51.804 257.032 50.832V50.04C257.032 49.068 256.456 48.312 254.8 48.312C253.144 48.312 252.568 49.068 252.568 50.04V50.832C252.568 51.804 253.144 52.56 254.8 52.56Z"
                fill="#FE6A6A"
            />
            <path
                opacity="0.5"
                d="M205.026 12.4V13.768H211.56L207.636 17.224V18.61H209.436C210.966 18.61 212.226 19.51 212.226 21.166C212.226 22.732 211.074 23.902 209.292 23.902C207.366 23.902 206.142 22.84 206.142 20.95V20.23H204.666V21.004C204.666 23.614 206.574 25.252 209.31 25.252C211.758 25.252 213.702 23.596 213.702 21.202C213.702 18.88 212.064 17.44 209.976 17.44H209.526V17.242L213.576 13.714V12.4H205.026Z"
                fill="#fff"
            />
            <rect x="97" y="1" width="98" height="98" stroke="#4D4F4F" strokeWidth="2" />
            <path
                d="M129.238 37.872V41.76H137.734V55.584C137.734 58.284 136.51 59.544 134.386 59.544C132.046 59.544 131.038 57.996 130.606 55.908L126.178 56.844C126.934 60.84 129.634 63.432 134.386 63.432C139.282 63.432 142.486 60.588 142.486 56.124V37.872H129.238ZM156.728 63.432C161.984 63.432 165.116 61.092 165.116 57.276C165.116 53.604 162.164 52.416 159.032 51.948L156.08 51.48C154.568 51.264 153.488 50.868 153.488 49.536C153.488 48.096 154.712 47.34 157.016 47.34C159.68 47.34 161.408 48.384 162.452 49.536L164.864 46.872C163.244 45.072 160.724 43.992 157.16 43.992C152.12 43.992 148.988 46.152 148.988 50.004C148.988 53.676 151.94 54.9 155.036 55.368L158.024 55.836C159.536 56.052 160.58 56.412 160.58 57.744C160.58 59.328 159.068 60.084 156.764 60.084C154.136 60.084 152.156 59.112 150.644 57.456L147.944 59.904C149.6 62.064 152.66 63.432 156.728 63.432Z"
                fill="#72D7D1"
            />
            <path
                opacity="0.5"
                d="M106.882 16.432V16.9H108.358V16.522C108.358 14.578 109.528 13.498 111.436 13.498C113.056 13.498 114.244 14.47 114.244 16.144C114.244 17.962 112.948 18.754 110.806 19.384C108.412 20.086 106.864 21.04 106.864 23.542V25H115.648V23.65H108.358V23.362C108.358 21.634 109.438 21.184 111.454 20.572C113.92 19.816 115.72 18.736 115.72 16.198C115.72 13.768 113.938 12.148 111.436 12.148C108.772 12.148 106.882 13.786 106.882 16.432Z"
                fill="#fff"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="390" height="100" fill="#fff" />
            </clipPath>
        </defs>
    </svg>
)
export default Logo2
